.hoverEffect:hover {
    opacity: .5;
    color: aqua;
}

.hoverEffect {
    margin-right: 10px;
}
.swal2-container {
    z-index:1400;
  }